<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-card outlined flat>
                            <v-card-title>
                                Subscriptions<v-spacer></v-spacer> <v-btn color="primary" @click="addSubscription(false)"
                                    icon><v-icon>add_circle_outline</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear indeterminate v-if="loadingSubscription"></v-progress-linear>
                                <v-list subheader dense>
                                    <v-list-item v-if="standardSubscriptions.length == 0">
                                        <v-list-item-content class="text-center">
                                            <span style="color: grey">No active subscriptions</span>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item v-for="sub in standardSubscriptions" :key="sub.id" @click="editSubscription(sub)">
                                       <v-list-item-action>
                                        <v-avatar><v-img contain v-if="sub.organisation.logo" :src="sub.organisation.logo"></v-img></v-avatar>
                                       </v-list-item-action>
                                       <v-list-item-content>
                                        <v-list-item-title>{{ sub.organisation.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span v-for="(bundle) in sub.subscriptionBundles" :key="bundle.id">
                                                <v-chip small class="mx-1" ><v-icon small left>local_offer</v-icon> {{ bundle.bundle.name }} <span v-if="bundle.discount" class="ml-1"> (-{{ bundle.discount }}%)</span></v-chip>
                                             </span></v-list-item-subtitle>
                                       </v-list-item-content>
                                       <v-list-item-action>
                                        <v-tooltip top  v-if="sub.suspended">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon color="danger">
                                                    <v-icon>block</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Suspended</span>
                                        </v-tooltip>
                                        <v-tooltip top  v-else>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon color="green">
                                                    <v-icon>task_alt</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Active</span>
                                        </v-tooltip>
                                       </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>

                        <v-card outlined flat>
                            <v-card-title>
                                Reverse Subscriptions <v-spacer></v-spacer> <v-btn color="primary"
                                    @click="addSubscription(true)" icon><v-icon>add_circle_outline</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear indeterminate v-if="loadingSubscription"></v-progress-linear>
                                <v-list subheader dense>
                                    <v-list-item v-if="reverseSubscriptions.length == 0">
                                        <v-list-item-content class="text-center">
                                            <span style="color: grey">No reverse subscriptions</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-for="sub in reverseSubscriptions" :key="sub.id" @click="editSubscription(sub)">
                                       <v-list-item-action>
                                        <v-avatar><v-img contain v-if="sub.organisation.logo" :src="sub.organisation.logo"></v-img></v-avatar>
                                       </v-list-item-action>
                                       <v-list-item-content>
                                        <v-list-item-title>{{ sub.organisation.name }}</v-list-item-title>
                                        <v-list-item-subtitle> <v-icon small>attach_money</v-icon> {{ sub.billingOrganisation.name }}</v-list-item-subtitle>

                                        <v-list-item-subtitle>
                                            <span v-for="(bundle) in sub.subscriptionBundles" :key="bundle.id">
                                                <v-chip small class="mx-1" ><v-icon small left>local_offer</v-icon> {{ bundle.bundle.name }} <span v-if="bundle.discount" class="ml-1"> (-{{ bundle.discount }}%)</span></v-chip>
                                             </span></v-list-item-subtitle>
                                       </v-list-item-content>
                                       <v-list-item-action>
                                        <v-tooltip top  v-if="sub.suspended">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon color="danger">
                                                    <v-icon>block</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Suspended</span>
                                        </v-tooltip>
                                        <v-tooltip top  v-else>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon color="green">
                                                    <v-icon>task_alt</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Active</span>
                                        </v-tooltip>
                                       </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-card flat>
                    <v-card-text></v-card-text>
                </v-card>
            </v-col>
        </v-row>
            </v-card-text>
        </v-card>
      


        <!-- Subscription -->
        <v-dialog v-model="subscriptionModal" persistent width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Manage Subscription
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-chip @click="subscriptionItem.suspended = !subscriptionItem.suspended" v-if="subscriptionItem.id && !subscriptionItem.suspended" color="green"><v-icon left>task_alt</v-icon> Account Active</v-chip>
                    <v-chip @click="subscriptionItem.suspended = !subscriptionItem.suspended" v-else-if="subscriptionItem.id && subscriptionItem.suspended" color="danger"><v-icon left>block</v-icon> Suspended</v-chip>

                    <v-btn color="primary" text :disabled="(!subscriptionItem.organisationId && !subscriptionItem.reverseBilling) || ((!subscriptionItem.organisationId || !subscriptionItem.billingOrganisationId) && subscriptionItem.reverseBilling)"
                        @click="saveSubscription" :loading="savingSubscription">Save</v-btn>
                    <v-btn text @click="subscriptionModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete id="organisationName"
                                outlined dense hide-no-data clearable
                                label="Organisation*" v-model="subscriptionItem.organisationId" :loading="searchingOrganisations"
                                :search-input.sync="searchOrganisations" :menu-props="{ closeOnContentClick: true }"
                                :items="organisations.data" item-text="name" item-value="id">
                                <template v-slot:item="{ item }">
                                    <v-list-item-action>
                                        <v-avatar><v-img contain v-if="item.logo" :src="item.logo"></v-img></v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                            <v-autocomplete id="organisationName"  outlined dense hide-no-data clearable
                            v-if="subscriptionItem.reverseBilling"
                                label="Billed Organisation*" v-model="subscriptionItem.billingOrganisationId" :loading="searchingBillingOrganisations"
                                :search-input.sync="searchBillingOrganisations" :menu-props="{ closeOnContentClick: true }"
                                :items="billingOrganisations.data" item-text="name" item-value="id">
                                <template v-slot:item="{ item }">
                                    <v-list-item-action>
                                        <v-avatar><v-img contain v-if="item.logo" :src="item.logo"></v-img></v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                            <v-select label="Billing Frequency" dense v-model="subscriptionItem.billingFrequency" outlined :items="['Monthly', 'Annually']"></v-select>
                            
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-subheader>Bundles</v-subheader>
                            <v-list dense subheader style="max-height:50vh; overflow-y:auto"
                                v-if="subscriptionItem && subscriptionItem.subscriptionBundles">
                                <v-list-item v-for="item in bundles" :key="item.id">
                                    <v-list-item-action-text class="text-center mx-2">
                                        <v-chip>$ {{ item.rate }}
                                        </v-chip>
                                        <br>
                                        <span style="font-size: 10px">{{ item.rateType }}</span>
                                    </v-list-item-action-text>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                            {{ item.description }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            <span v-for="(bundleModule, bundleIndex) in item.bundleModules"
                                                :key="bundleModule.id"> {{ bundleModule.systemModule.name }} <span
                                                    v-if="bundleIndex < item.bundleModules.length - 1"> -
                                                </span></span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action :key="subscriptionkey">
                                        <v-tooltip top
                                            v-if="!subscriptionItem.subscriptionBundles.map(x => x.bundleId).includes(item.id)">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="changeBundleSubscription(item)">
                                                    <v-icon>add_card</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add subscription</span>
                                        </v-tooltip>
                                        <v-tooltip top v-else>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="changeBundleSubscription(item)">
                                                    <v-icon color="blue">verified</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Subscribed</span>
                                        </v-tooltip>

                                        <v-tooltip top v-if="subscriptionItem.subscriptionBundles.map(x => x.bundleId).includes(item.id)">
                                            <template v-slot:activator="{ on }">
                                                <v-chip small v-on="on" @click="editDiscount(item)"><v-icon small left>discount</v-icon> {{ bundleDiscount(item) }} %</v-chip>
                                            </template>
                                            <span>Applied Discount</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="discountModal" width="250px" :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
            <v-toolbar flat color="transparent">
                <v-spacer></v-spacer>
                <v-btn text @click="updateDiscount" color="success" icon><v-icon>check</v-icon></v-btn>
                <v-btn text @click="discountModal = false">X</v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field outlined suffix="%" v-model="discountItem.discount" type="number" :min="0" :max="100"></v-text-field>
            </v-card-text>
        </v-card></v-dialog>
    </div>
</template>

<script>
export default {
data: ()=>({
    bundle: {},
        bundles: [],
        bundleModal: false,

        discountModal: false,
        discountItem: {},

        loadingMetrics: false,
        loadingSubscription: false,
        loadingBundle: false,

        metrics: {},
        modules: [],
        priceTypes: [
            "Per Pallet",
            "Per Container",
            "Per Shipment",
            "Flat Rate"
        ],
        refreshModules: 0,
        savingBundle: false,
        organisationTimeout: null,
        organisations: { data: [] },
        searchingOrganisations: false,
        searchOrganisations: null,

        billingOrganisationTimeout: null,
        billingOrganisations: { data: [] },
        searchingBillingOrganisations: false,
        searchBillingOrganisations: null,

        subscriptionModal: false,
        subscriptionItem: {},
        subscriptions: [],
        subscriptionkey: 0,
        savingSubscription: false
}),
watch: {
    '$route.params.id': function() {
        this.getSubscriptions()
    },
        async searchBillingOrganisations(val) {
            if (this.searchingBillingOrganisations) {
                clearTimeout(this.billingOrganisationTimeout);
            }
            if (val && val.length > 2) {
                this.billingOrganisationTimeout = setTimeout(async () => {
                    this.searchingBillingOrganisations = true;
                    this.billingOrganisations = await this.$API.listOrganisationsAdmin({
                        params: {
                            search: val,
                            limit: 15,
                        },
                    });
                    this.searchingBillingOrganisations = false;
                }, 150);
                this.searchingBillingOrganisations = false;
            } else {
                clearTimeout(this.billingOrganisationTimeout);
                this.searchingBillingOrganisations = false;
            }
        },
        async searchOrganisations(val) {
            if (this.searchingOrganisations) {
                clearTimeout(this.organisationTimeout);
            }
            if (val && val.length > 2) {
                this.organisationTimeout = setTimeout(async () => {
                    this.searchingOrganisations = true;
                    this.organisations = await this.$API.listOrganisationsAdmin({
                        params: {
                            search: val,
                            limit: 15,
                        },
                    });
                    this.searchingOrganisations = false;
                }, 150);
                this.searchingOrganisations = false;
            } else {
                clearTimeout(this.organisationTimeout);
                this.searchingOrganisations = false;
            }
    },
},
computed: {
        standardSubscriptions() {
            return this.subscriptions.filter(x=>!x.reverseBilling)
        },
        reverseSubscriptions() {
            return this.subscriptions.filter(x=>x.reverseBilling)
        }
    },
    created(){
        this.getSubscriptions()
        this.getBundles()
        this.getModules()
    },
methods: {
    addBundle() {
            this.bundle = {
                bundleModules: [],
                rateType: this.priceTypes[0]
            }
            this.bundleModal = true
        },
        addSubscription(reverse = false) {
            this.subscriptionItem = {
                billingFrequency: "Monthly",
                reverseBilling: reverse,
                subscriptionBundles: [],
                suspended: false
            }
            this.subscriptionModal = true
        },
        bundleDiscount(item){
            let find = this.subscriptionItem.subscriptionBundles.find(x => x.bundleId == item.id)
            return find.discount
        },
        editBundle(item) {
            this.bundle = item
            this.bundleModal = true
        },
        editDiscount(item) {
            let find = this.subscriptionItem.subscriptionBundles.find(x => x.bundleId == item.id)
            this.discountItem = find
            this.discountModal = true
        },
        editSubscription(item) {
            this.subscriptionItem = item
            this.organisations.data.push(this.subscriptionItem.organisation)
            this.billingOrganisations.data.push(this.subscriptionItem.billingOrganisation)
            this.subscriptionModal = true
        },
        changeModuleSubscription(item) {
            if (this.bundle.bundleModules.map(x => x.systemModuleId).includes(item.id)) {
                this.bundle.bundleModules = this.bundle.bundleModules.filter(x => x.systemModuleId != item.id)
            } else {
                let findModule = this.modules.find(x => x.id == item.id)
                this.bundle.bundleModules.push({ systemModuleId: item.id, systemModule: findModule })
            }
            this.refreshModules++
        },
        async changeBundleSubscription(item) {
            if (this.subscriptionItem.subscriptionBundles.map(x => x.bundleId).includes(item.id)) {
                let find = this.subscriptionItem.subscriptionBundles.find(x => x.bundleId == item.id)

                this.subscriptionItem.subscriptionBundles = this.subscriptionItem.subscriptionBundles.filter(x => x.bundleId != item.id)
                if(find && find.id){
                    await this.$API.updateSubscriptionBundle({
                        id: find.id,
                        isActive: false,
                        isDeleted: true
                    })
                }
                
            } else {
                let findBundle = this.bundles.find(x => x.id == item.id)
                this.subscriptionItem.subscriptionBundles.push({ bundleId: item.id, bundle: findBundle, discount:0 })
                if(this.subscriptionItem.id){
                    await this.$API.createSubscriptionBundle({
                        subscriptionId: this.subscriptionItem.id,
                        bundleId: item.id,
                        discount:0
                    })
                }
            }
            this.subscriptionkey++
        },
        async getPlatformMetrics() {
            this.loadingMetrics = true
            this.metrics = await this.$API.getPlatformMetrics()
            this.loadingMetrics = false
        },
        async getBundles() {
            this.loadingBundle = true
            this.bundles = await this.$API.getBundles()
            this.loadingBundle = false
        },
        async getModules() {
            let modules = await this.$API.getModules({})
            this.modules = modules.data
        },
        async saveBundle() {
            this.savingBundle = true
            if (this.bundle.id) {
                await this.$API.updateBundle(this.bundle)
            } else {
                await this.$API.createBundle(this.bundle)
            }
            this.bundleModal = false
            this.bundle = {}
            this.getBundles()
            this.getSubscriptions()
            this.savingBundle = false
        },
        async saveSubscription() {
            this.savingSubscription = true
            if(!this.subscriptionItem.reverseBilling){
                this.subscriptionItem.billingOrganisationId = this.subscriptionItem.organisationId
            }
            if (this.subscriptionItem.id) {
                await this.$API.updateSubscription(this.subscriptionItem)
            } else {
                await this.$API.createSubscription(this.subscriptionItem)
            }
            this.subscriptionModal = false
            this.subscriptionItem = {}
            this.getSubscriptions()
            this.savingSubscription = false
        },
        async updateDiscount() {
            let findIndex = this.subscriptionItem.subscriptionBundles.findIndex(x => x.bundleId == this.discountItem.id)
            this.subscriptionItem.subscriptionBundles[findIndex] = this.discountItem
            if(this.discountItem.id){
                    await this.$API.updateSubscriptionBundle(this.discountItem)
            }
            this.discountModal = false
            this.discountItem = {}
        },
  
    async getSubscriptions() {
            this.loadingSubscription = true
            this.subscriptions = await this.$API.getOrganisationSubscriptions(this.$route.params.id)
            this.loadingSubscription = false
        },
    }

}
</script>
